/* MobileSetCardDisplay specific styles with unique class names */
.mobile-card-view-wrapper {
    padding: 12px;
    background-color: transparent;
    width: 100%;
    box-sizing: border-box;
}

.mobile-card-view-container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
    width: 100%;
}

.mobile-card-item {
    /* Base styling for all cards */
    position: relative;
    transition: transform 0.2s ease;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    
    /* Fixed aspect ratio approach for mobile */
    flex: 0 0 calc(50% - 8px);
    width: calc(50% - 8px);
    min-width: 140px;
    max-width: 180px;
    background-color: white;
}

.mobile-card-item:hover {
    transform: translateY(-2px);
}

/* Component-specific styles */
.mobile-card-content-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.mobile-card-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    /* Set aspect ratio for card images */
    aspect-ratio: 2.5/3.5;
    padding: 0;
    border-radius: 10px;
    overflow: hidden;
}

.mobile-card-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
}

/* Card Information Section */
.mobile-card-info-section {
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-radius: 10px;
}

.mobile-card-name-price-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mobile-card-title {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70%;
}

.mobile-card-price-display {
    font-size: 0.85rem;
    font-weight: 500;
}

.mobile-card-price-display p {
    margin: 0;
}

.mobile-card-number-pokeball-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mobile-card-number-display {
    font-size: 0.8rem;
    color: #666;
}

.mobile-card-number-display p {
    margin: 0;
}

.mobile-pokeball-container {
    cursor: pointer;
}

.mobile-pokeball-img {
    transition: transform 0.2s ease;
    width: 24px;
    height: 24px;
}

.mobile-pokeball-img:hover {
    transform: scale(1.1);
}

/* Mobile-specific responsive breakpoints */
@media (min-width: 500px) {
    .mobile-card-item {
        flex: 0 0 calc(33.333% - 8px);
        width: calc(33.333% - 8px);
    }
}

@media (min-width: 700px) {
    .mobile-card-item {
        flex: 0 0 calc(25% - 12px);
        width: calc(25% - 12px);
    }
    
    .mobile-card-view-container {
        gap: 16px;
    }
}

/* Very small screens */
@media (max-width: 350px) {
    .mobile-card-view-container {
        gap: 8px;
    }
    
    .mobile-card-item {
        flex: 0 0 calc(50% - 4px);
        width: calc(50% - 4px);
        min-width: 120px;
    }
    
    .mobile-card-title {
        font-size: 0.8rem;
    }
    
    .mobile-card-price-display {
        font-size: 0.75rem;
    }
    
    .mobile-card-number-display {
        font-size: 0.7rem;
    }
    
    .mobile-pokeball-img {
        width: 20px;
        height: 20px;
    }
} 