.card-template-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Set a high z-index value */
}

.card-template-container {
    background-color: white; /* Adjust the background color as needed */
    padding: 20px;
    border-radius: 10px;
    width: 90%; /* Adjust the width as needed */
    height: 90%; /* Adjust the height as needed */
    max-width: 1300px; /* Set a maximum width if desired */
    max-height: 800px; /* Set a maximum height if desired */
    overflow: auto;
    z-index: 1001; /* Set a higher z-index value than the overlay */
}

/* Media query for screens with a maximum width of 790px (mobile) */
@media (max-width: 790px) {
    .card-template-overlay {
        top: 56px; /* Adjust based on the top bar height */
        height: calc(
            100% - 50px
        ); /* Adjust to fill the remaining height after accounting for the top bar */
    }
    .card-template-overlay {
        left: 0;
        width: 100%;
    }
}

/* Media query for screens with a minimum width of 791px (desktop) */
@media (min-width: 791px) {
    .card-template-overlay {
        left: 300px; /* Adjust based on the sidebar width */
        width: calc(100% - 300px); /* Adjust to fill the remaining width */
    }
}

.card-template-content {
    display: flex;
}

.left-column {
    flex: 1;
    margin-right: 20px;
}

.right-column {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.card-info {
    margin-bottom: 20px;
}

.cardTemplate-close-btn {
    cursor: pointer;
}

.cardTemplate-card-image {
    border-radius: 10px;
    z-index: 100;
}

.cardTemplate-desktop-pokeball {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.vertical-align-container {
    display: flex;
    align-items: center;
}

.MuiCard-root {
    border: none !important;
    box-shadow: none !important;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 1300px;
    max-height: 90vh;
    overflow: auto;
    position: relative;
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup-title {
    margin: 0;
    font-size: 1.5rem;
}

.popup-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-content {
    overflow: auto;
}
