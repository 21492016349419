.login-section {
    display: grid;
    grid-template-columns: 1fr minmax(200px, 400px) 1fr;
    /* grid-template-rows: 1fr minmax(auto,1fr) 1fr; */
    /* grid-gap: 10px;
  width: 100%;
  height: 100vh; */
    /* background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB); */
    background-size: 400% 400%;
    animation: Gradient 15s ease infinite;
    box-sizing: border-box;
}
.login-form {
    grid-column: 2;
    grid-row: 2;
    display: grid;
    grid-gap: 10px;
    margin: auto 0;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0 32px 64px rgba(0, 0, 0, 0.2);
}

.login-h2 {
    margin-bottom: 5px;
    text-align: center;
    text-shadow: 0 4px 16px #fff;
    font-size: 30px;
    font-weight: 100;
}

.login-fieldset {
    margin: 0;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.login-legend {
    padding: 5px;
    background-color: #fff;
    border-radius: 5px;
}

.login-ul {
    margin: 0;
    padding: 0;
}

.login-li {
    display: grid;
    align-items: center;
    margin: 10px;
}

.login-a {
    color: #02c;
}

.login-em {
    grid-column: span 2;
    text-align: center;
    padding: 5px;
}

.login-label {
    text-align: left;
    padding-bottom: 2px;
}

.login-input {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.login-input:hover {
    border: 1px solid #aaf;
}

.login-button {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.login-button:hover {
    background-color: #eef;
    border: 1px solid #aaf;
}

@keyframes Gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.form-container {
    display: flex;
    align-items: center;
}

.cardTemplate-card-image {
    flex: 0 0 auto; /* Let the image shrink if necessary */
    max-width: 400px; /* Limit the maximum width of the image */
    margin-right: 20px; /* Adjust margin as needed */
}

.login-section {
    flex: 1; /* Let the form grow to fill the remaining space */
}

.country-selector-wrapper {
    width: 100%;
}

.country-selector-wrapper .MuiSelect-select {
    display: flex;
    align-items: center;
    max-width: 100%;
}

.country-selector .MuiSelect-select {
    display: flex;
    align-items: center;
}

.country-selector .country-flag {
    flex-shrink: 0;
    margin-right: 8px;
}

.country-selector .country-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 32px); /* Adjust based on flag width and margin */
}

.login-input,
.country-selector-wrapper {
    width: 100%;
}

.country-selector {
    width: 100%;
}

.country-selector .MuiOutlinedInput-root {
    border-radius: 4px;
}

.country-selector .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
}

.country-selector:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.87);
}

.country-selector.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #1976d2;
    border-width: 2px;
}
