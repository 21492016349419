.pokemon-set-manager-table {
    margin-top: 20px;
}

.pokemon-set-manager-table .MuiTableCell-root {
    padding: 8px;
}

.pokemon-set-manager-table .MuiTableCell-head {
    font-weight: bold;
    background-color: #f5f5f5;
}

.pokemon-set-manager-table .MuiIconButton-root {
    padding: 6px;
}

.pokemon-set-manager-table .MuiTableRow-root:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.pokemon-set-manager-table .MuiTableCell-body {
    font-size: 0.875rem;
} 