.desktop-set-cards-container {
    margin-top: 8px;
    margin-left: 8px;
    margin-right: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.desk-card-container {
    flex: 0 0 calc(16.666% - 16px);
    height: 300px;
    position: relative;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.desk-card-container.drop-target {
    position: relative;
}

.desk-card-container.drop-target::before {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border: 2px dashed #1976d2;
    border-radius: 8px;
    background-color: rgba(25, 118, 210, 0.05);
    z-index: 5;
    pointer-events: none;
}

.desk-card-container.dragging {
    opacity: 0.6;
    transform: scale(1.02);
}

@media (max-width: 1600px) {
    .desk-card-container {
        flex: 0 0 calc(20% - 16px);
    }
}

@media (max-width: 1200px) {
    .desk-card-container {
        flex: 0 0 calc(25% - 16px);
    }
}

@media (max-width: 900px) {
    .desk-card-container {
        flex: 0 0 calc(33.333% - 16px);
    }
}

@media (max-width: 600px) {
    .desk-card-container {
        flex: 0 0 calc(50% - 16px);
    }
}

.card-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-image {
    height: auto;
    max-width: 100%;
    max-height: 140px;
    object-fit: contain;
    border-radius: 10px;
    margin: 0;
    padding: 0;
}

.dragged-item {
    z-index: 1000;
    pointer-events: none;
    position: absolute;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
    opacity: 0.9;
    background-color: white;
    border-radius: 8px;
}

.drag-handle {
    cursor: grab;
    color: rgba(0, 0, 0, 0.5);
    transition: color 0.2s ease, transform 0.2s ease, background-color 0.2s ease;
    width: 24px;
    height: 24px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: 10;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.drag-handle:hover {
    color: rgba(25, 118, 210, 1);
    transform: scale(1.1);
    background-color: rgba(255, 255, 255, 0.95);
}

.drag-handle:active {
    cursor: grabbing;
    transform: scale(1.2);
    color: #1565c0;
}

.card-type {
    font-style: italic;
    font-size: 0.75rem;
    margin-top: 4px;
}

.reverse-holo {
    color: #1976d2;
}

.normal-card {
    color: rgba(0, 0, 0, 0.6);
}

.card-index {
    font-weight: bold;
    font-size: 0.875rem;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 4px 8px;
    margin-top: 8px;
    text-align: center;
}

.drop-gap-indicator {
    position: absolute;
    border: 3px dashed #1976d2;
    background-color: rgba(25, 118, 210, 0.1);
    border-radius: 8px;
    z-index: 5;
    pointer-events: none;
    animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
    0% { opacity: 0.6; }
    50% { opacity: 0.8; }
    100% { opacity: 0.6; }
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1200;
    backdrop-filter: blur(2px);
} 