.sidebar-userProfile {
    display: flex;
    align-items: center;
    margin-left: 24px; /* Adjust this value as needed */
}

.sidebar-userProfile p {
    margin-left: 15px; /* Adjust this value as needed */
    color: #fff400;
}
