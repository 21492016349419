.hero-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 900px;
    background-image: url('https://mypokellection.com/ImageAssets/Hero1.png');
    background-size: cover;
    background-position: center;
}

.hero-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);
    padding: 20px;
    border-radius: 50px;
}

.hero-header h1 {
    font-size: 72px;
    text-align: center;
    font-weight: 700;
    color: white;
    -webkit-text-stroke: 1px black;
    margin-bottom: 10px;
}

.hero-header .hero-subtext p {
    font-size: 20px;
    font-weight: 400;
    color: white;
    margin-top: 0;
    margin-bottom: 10px;
}

.hero-login-button {
    background-color: #fff400;
    color: #000000;
    border: 1px solid #000000;
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: 100;
    cursor: pointer;
}
