/* Sealed Products Styling */

/* Card Box Styles */
.sealed-desktop-card-box,
.sealed-mobile-card-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevent content from overflowing */
}

.sealed-mobile-card-box {
  max-width: 150px;
}

.sealed-desktop-card-box {
  max-width: 200px;
}

/* Card Image Container */
.sealed-card-image-container {
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  padding: 12px;
  box-sizing: border-box;
  overflow: hidden;
}

.sealed-image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sealed-card-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

/* Card Info Section */
.sealed-card-info-section {
  padding: 12px;
  display: flex;
  flex-direction: column;
  background-color: white;
  flex-grow: 1;
  width: 100%; /* Ensure full width */
  box-sizing: border-box; /* Include padding in width calculation */
}

/* Card Name and Price */
.sealed-card-name-price {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  height: 100%;
  width: 100%; /* Ensure full width */
}

.sealed-card-name {
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0 0 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.2;
  min-height: 2.4em;
  text-align: center;
  width: 100%;
}

.sealed-card-price {
  margin-top: auto;
  text-align: center;
  width: 100%; /* Ensure full width */
}

.sealed-card-price p {
  font-weight: bold;
  color: #2e7d32; /* Green color */
  margin: 0;
  font-size: 1.1rem;
}

/* Card Number and Pokeball */
.sealed-card-number-pokeball {
  display: flex;
  justify-content: center; /* Center the pre-sale chip */
  align-items: center;
  margin-top: 8px;
  width: 100%; /* Ensure full width */
}

.sealed-card-number {
  font-size: 0.8rem;
  color: #666;
}

.sealed-card-number p {
  margin: 0;
}

/* Pokeball */
.sealed-pokeball-container {
  display: flex;
  align-items: center;
}

.sealed-mobile-pokeball {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.sealed-desktop-pokeball {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

/* Modal Styling */
.sealed-product-modal .MuiPaper-root {
  border-radius: 12px;
  overflow: hidden;
}

.sealed-product-modal .MuiDialogTitle-root {
  background-color: #1976d2;
  color: white;
  font-weight: bold;
}

/* Price History Chart */
.price-history-chart {
  border-radius: 8px;
  padding: 16px;
  background-color: #f9f9f9;
} 