.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    height: 90%;
    max-width: 1300px;
    max-height: 800px;
    overflow: auto;
    z-index: 1001;
    position: relative;
    display: flex;
    flex-direction: column;
}

@media (max-width: 790px) {
    .popup-overlay {
        top: 56px;
        height: calc(100% - 50px);
    }
    .popup-overlay {
        left: 0;
        width: 100%;
    }
}

@media (min-width: 791px) {
    .popup-overlay {
        left: 300px;
        width: calc(100% - 300px);
    }
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup-title {
    font-size: 1.5rem;
    font-weight: bold;
}

.popup-close-btn {
    cursor: pointer;
    font-size: 1.5rem;
    background: none;
    border: none;
    color: #333;
}

.popup-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.profile-pic-section {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.favorite-pokemon-section {
    align-self: flex-start;
    width: 100%;
}

.section-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.section-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
    max-width: 100%;
}

.popup-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.popup-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.popup-button-primary {
    background-color: #007bff;
    color: white;
}

.popup-button-secondary {
    background-color: #6c757d;
    color: white;
}

/* Autocomplete styles */
.MuiAutocomplete-popper {
    max-height: 300px;
    overflow-y: auto;
}

.MuiAutocomplete-listbox {
    max-height: none !important;
}
