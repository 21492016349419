/* DesktopSetCardDisplay specific styles with unique class names */
.desktop-card-view-wrapper {
    padding: 16px;
    background-color: transparent;
    width: 100%;
    box-sizing: border-box;
}

.desktop-card-view-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    width: 100%;
}

.desktop-card-item {
    /* Base styling for all cards */
    position: relative;
    transition: transform 0.3s ease;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
    
    /* Fixed aspect ratio approach */
    flex: 0 0 calc(16.666% - 16px);
    width: calc(16.666% - 16px);
    min-width: 160px;
    max-width: 220px;
}

.desktop-card-item:hover {
    transform: translateY(-4px);
}

/* Other component-specific styles as needed */
.desktop-card-content-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.desktop-card-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    /* Set aspect ratio for card images */
    aspect-ratio: 2.5/3.5;
}

.desktop-card-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Card Information Section */
.desktop-card-info-section {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.desktop-card-name-price-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.desktop-card-title {
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70%;
}

.desktop-card-price-display {
    font-size: 0.9rem;
    font-weight: 500;
}

.desktop-card-price-display p {
    margin: 0;
}

.desktop-card-number-pokeball-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.desktop-card-number-display {
    font-size: 0.85rem;
    color: #666;
}

.desktop-card-number-display p {
    margin: 0;
}

.desktop-pokeball-container {
    cursor: pointer;
}

.desktop-pokeball-img {
    transition: transform 0.2s ease;
    width: 30px;
    height: 30px;
}

.desktop-pokeball-img:hover {
    transform: scale(1.1);
}

/* Responsive styles with cleaner breakpoints */
@media (max-width: 1600px) {
    .desktop-card-item {
        flex: 0 0 calc(20% - 16px);
        width: calc(20% - 16px);
    }
}

@media (max-width: 1200px) {
    .desktop-card-item {
        flex: 0 0 calc(25% - 16px);
        width: calc(25% - 16px);
    }
}

@media (max-width: 900px) {
    .desktop-card-item {
        flex: 0 0 calc(33.333% - 16px);
        width: calc(33.333% - 16px);
    }
}

@media (max-width: 600px) {
    .desktop-card-item {
        flex: 0 0 calc(50% - 16px);
        width: calc(50% - 16px);
    }
}

/* Ensure minimum 2 cards on smallest screens */
@media (max-width: 400px) {
    .desktop-card-view-container {
        gap: 12px;
    }
    
    .desktop-card-item {
        flex: 0 0 calc(50% - 12px);
        width: calc(50% - 12px);
        min-width: 140px;
    }
} 