/* Admin Card Display - Common styles for admin tools */
/* CameoCardManager, CardCategoryManager, CardImageManager, FeaturedCardManager */

.admin-card-view-wrapper {
    padding: 16px;
    background-color: transparent;
    width: 100%;
    box-sizing: border-box;
}

.admin-card-view-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    width: 100%;
}

.admin-card-item {
    /* Base styling for all cards */
    position: relative;
    transition: transform 0.3s ease;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    background-color: white;
    
    /* Fixed aspect ratio approach */
    flex: 0 0 calc(16.666% - 16px);
    width: calc(16.666% - 16px);
    min-width: 160px;
    max-width: 220px;
}

.admin-card-item:hover {
    transform: translateY(-4px);
}

/* Card content structure */
.admin-card-content-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.admin-card-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    /* Set aspect ratio for card images */
    aspect-ratio: 2.5/3.5;
    padding: 0;
    border-radius: 10px;
    overflow: hidden;
}

.admin-card-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
    border-radius: 10px;
}

/* Card Information Section */
.admin-card-info-section {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 10px;
}

.admin-card-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.admin-card-title {
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70%;
}

.admin-card-subtitle {
    font-size: 0.85rem;
    color: #666;
    margin: 0;
}

/* Admin-specific controls */
.admin-card-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

.admin-card-checkbox {
    margin: 0;
}

.admin-card-actions {
    display: flex;
    gap: 8px;
}

/* Selection indicator */
.admin-card-selected {
    box-shadow: 0 0 0 3px #3f51b5 !important;
}

/* Drag and drop styles */
.admin-card-draggable {
    cursor: grab;
}

.admin-card-dragging {
    opacity: 0.7;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3) !important;
    cursor: grabbing !important;
}

.admin-card-drop-target {
    box-shadow: 0 0 0 2px #4caf50 !important;
}

/* Responsive styles for admin cards */
@media (max-width: 1600px) {
    .admin-card-item {
        flex: 0 0 calc(20% - 16px);
        width: calc(20% - 16px);
    }
}

@media (max-width: 1200px) {
    .admin-card-item {
        flex: 0 0 calc(25% - 16px);
        width: calc(25% - 16px);
    }
}

@media (max-width: 900px) {
    .admin-card-item {
        flex: 0 0 calc(33.333% - 16px);
        width: calc(33.333% - 16px);
    }
}

@media (max-width: 600px) {
    .admin-card-item {
        flex: 0 0 calc(50% - 16px);
        width: calc(50% - 16px);
    }
    
    .admin-card-title {
        font-size: 0.9rem;
    }
}

/* Additional tools for admin */
.admin-card-status-indicator {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.admin-status-active {
    background-color: #4caf50; /* Green */
}

.admin-status-inactive {
    background-color: #f44336; /* Red */
}

.admin-status-pending {
    background-color: #ff9800; /* Orange */
}

/* Loading overlay */
.admin-loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
} 