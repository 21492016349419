.route-manager-table {
    width: 100%;
    overflow-x: auto;
}

.route-manager-cell {
    min-width: 150px;
    padding: 8px;
}

.route-manager-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.route-manager-actions {
    min-width: 100px;
} 