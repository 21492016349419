.footer-container {
    padding: 8px 16px;
    margin-top: auto;  /* Push to bottom */
}

.privacy-policy-link {
    font-size: 12px;
    color: #666;
    text-decoration: none;
    display: block;
    margin-bottom: 8px;
}

.privacy-policy-link:hover {
    text-decoration: underline;
}

.footer-text {
    font-size: 10px;
    color: #666;
    line-height: 1.2;
    margin: 0;
    text-align: center;
    font-style: italic;
} 